import React, { useEffect } from 'react';
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom';
import { AuthContext, useAuth, useProvideAuth } from 'common/auth';
import { useGoogleLogin } from '@react-oauth/google';
import {Avatar, Button, Grid} from "@material-ui/core";
import google from 'assets/google.png';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";


export function ProvideAuth({ children }) {
  const token = localStorage.getItem('hijackToken');
  const user = localStorage.getItem('hijackUserObj');
  const parsedToken = token !== 'undefined' ? JSON.parse(token) : null;
  const parsedUser = token !== 'undefined' ? JSON.parse(user) : null;
  const auth = useProvideAuth({token: parsedToken, user: parsedUser});

  useEffect(() => {
    if (localStorage.getItem('hijackUser') !== 'true'){
      auth.performCheck();
    }
    if (localStorage.getItem('hijackToken') === 'undefined') {
      localStorage.setItem('hijackUser', 'false');
    }
  }, []);

  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  );
}

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(2)
  },
}));

export function LoginButton() {
  const classes = useStyles();
  const history = useHistory(),
    location = useLocation(),
    auth = useAuth();

  const { from } = location.state || { from: { pathname: '/' } };

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      auth.signIn(tokenResponse).then(() => history.replace(from));
    },
    onError: errorResponse => console.log(errorResponse),
  });

  return <Button
    onClick={() => googleLogin()}
    variant='outlined'
  >
    <Grid container>
      <Avatar src={google} className={classes.small}/>
      <Typography>Sign in</Typography>
    </Grid>
  </Button>;
}


export function PrivateRoute({ children, ...rest }) {
  const auth = useAuth();

  return (
    <Route
      {...rest}
      render={({location}) => auth.user ? (children) : (
        <Redirect to={{
          pathname: '/sign-in',
          state: { from: location },
        }}/>
      )}
    />
  );
}

export function CalendarPrivateRoute({ children, ...rest }) {
  const auth = useAuth();
  const user = auth.user;
  return (
    <Route
      {...rest}
      render={({location}) => user && user.enable_hebcal_calendar ? (children) : (
        <Redirect to={{
          pathname: '/',
          state: { from: location },
        }}/>
      )}
    />
  );
}

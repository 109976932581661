import React, {useState} from 'react';
import {makeStyles, Paper} from "@material-ui/core";
import {QuestionBoxContent} from "../utils/QuestionBox";
import {STEPS} from "../WizardStepper";


const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        flexGrow: 1,
    },
    additionalQuestion: {
        marginTop: theme.spacing(2),
    },
}));

export function HolidayQuestionBox(props) {
    const classes = useStyles();
    const [activeButtonYes, setActiveButton] = useState(props.holiday && props.holiday.enable);
    const [chActiveButtonYes, setChActiveButtonYes] = useState(props.cholHamoed && props.cholHamoed.enable);

    const handleButtonClick = (value) => {
        setActiveButton(value);
        props.onClick(value);
        if (props.holiday.holiday_name === STEPS[1] && !props.settingsComponent && value === true) {
            props.enableHolidaysBatch();
        }
    };

    const handleCHButtonClick = (value) => {
        setChActiveButtonYes(value);
        props.handleCholHamoed(value);
    };

    return (
        <div className={classes.root}>
            <QuestionBoxContent
                enabled={activeButtonYes}
                questionText={`Do you want the system to respond on ${props.holiday.holiday_name}?`}
                handleClick={handleButtonClick}
            />
            {props.cholHamoed && <div className={classes.additionalQuestion}>
                <QuestionBoxContent
                    enabled={chActiveButtonYes}
                    questionText={`Do you want the system to respond on ${props.cholHamoed.assets.common_name}?`}
                    handleClick={handleCHButtonClick}
                />
            </div>}
        </div>
    );
}

export function AnotherHolidayQuestionBox(props){
    const classes = useStyles();
    const text = `Do you want the Autoresponder to use the same settings that were used
     in ${props.assets.holiday_name} for ${props.assets.another_holiday_name}?`;
    const [enabled, setEnabled] = useState(props.enabledAnotherHoliday);
    const handleClick = (value) => {
        // here should be updateAnotherHoliday function that will send req to create holiday with the same settings
        setEnabled(value);
        props.onClick(value);
    };

    return(
      <Paper className={classes.root}>
          <QuestionBoxContent
            enabled={enabled}
            questionText={text}
            handleClick={handleClick}
          />
      </Paper>
    );
}

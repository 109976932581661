import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch, } from 'react-router-dom';
import SignIn from './SignIn';
import {CalendarPrivateRoute, PrivateRoute, ProvideAuth} from './Auth';
import Settings from './Settings';
import RepliedEmails from './RepliedEmails';
import AuthorizedWrapper from './AuthorizedWrapper';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import API from 'common/API';
import './App.css';
import DialogModal from "./EnableDialogModal";
import {HijackUser} from "./Hijack/HijackUser";
import Calendar from "./Calendar";
import CalendarWizard from "./CalendarWizard";
import EventsList from "./EventsList";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GOOGLE_CLIENT_ID } from 'common/variables';


function Root() {
  return (
    <Router>
      <Switch>
        <Route path="/sign-in">
          <SignIn />
        </Route>
        <Route path="/hijack-user">
          <HijackUser />
        </Route>
        <PrivateRoute exact path="/logs">
          <AuthorizedWrapper>
            <RepliedEmails />
          </AuthorizedWrapper>
        </PrivateRoute>
        <PrivateRoute exact path="/">
          <AuthorizedWrapper>
            <DialogModal/>
            <Settings />
          </AuthorizedWrapper>
        </PrivateRoute>
        <CalendarPrivateRoute exact path="/calendar">
          <AuthorizedWrapper>
            <Calendar />
          </AuthorizedWrapper>
        </CalendarPrivateRoute>
        <CalendarPrivateRoute exact path="/wizard">
          <AuthorizedWrapper>
            <CalendarWizard />
          </AuthorizedWrapper>
        </CalendarPrivateRoute>
        <CalendarPrivateRoute exact path="/events">
          <AuthorizedWrapper>
            <EventsList />
          </AuthorizedWrapper>
        </CalendarPrivateRoute>
        <PrivateRoute path="*">
          <Redirect to="/" />
        </PrivateRoute>
      </Switch>
    </Router>
  );
}


export default function App() {
  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <ProvideAuth>
        <API>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Root/>
          </MuiPickersUtilsProvider>
        </API>
      </ProvideAuth>
    </GoogleOAuthProvider>
  );
}

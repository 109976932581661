import React, { useEffect, useState } from "react";
import { useAPI } from "../../common/API";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import { Typography } from "@material-ui/core";
import { Button, Snackbar } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Alert } from "@material-ui/lab";
import {
    KeyboardDateTimePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { parseErrors } from "../../utils/ parseErrors";

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    formControl: {
        margin: theme.spacing(1),
    },
    selector: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));

const columns = [
    { id: "holiday_name", label: "Holiday Name", minWidth: 200 },
    { id: "start_date", label: "Start Time", minWidth: 200 },
    { id: "end_date", label: "End Date", minWidth: 200 },
    { id: "save", lable: "Save", minWidth: 200 },
];

function getUserDateTime(dateString) {
    return new Date(dateString);
}

const HolidaysOptionSelector = (props) => {
    const classes = useStyles();

    return (
        <FormControl className={classes.formControl}>
            <Select
                labelId="holidays-duration-select-helper-label"
                id="holidays-duration-select-helper"
                value={props.selectedOption}
                onChange={(e) => props.setSelectedOption(e.target.value)}
            >
                <MenuItem value={"original"}>Holidays Dates</MenuItem>
                <MenuItem value={"rules"}>Holidays Response Times</MenuItem>
            </Select>
            <FormHelperText>Holidays Duration Options</FormHelperText>
        </FormControl>
    );
};

export default function EventsList() {
    const api = useAPI();
    const classes = useStyles();
    const [eventsList, setEventsList] = useState([]);
    const responseTimesList =
        eventsList &&
        eventsList
            .filter((holiday) => holiday.rule && holiday.enable)
            .map(
                (holiday) =>
                    holiday.rule && {
                        holiday_name: holiday.holiday_name,
                        start_date: holiday.rule.start_date,
                        holiday_start_date: holiday.start_date,
                        end_date: holiday.rule.end_date,
                        holiday_end_date: holiday.start_date,
                        response_rule: true,
                    }
            )
            .sort(function (a, b) {
                return (
                    new Date(a.holiday_start_date) -
                    new Date(b.holiday_start_date)
                );
            });
    const [selectedOption, setSelectedOption] = useState("rules");
    const [selectedEvents, setSelectedEvents] = useState([]);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [successAlert, setSuccessAlert] = useState();
    const [errors, setErrors] = useState();

    const parseResponseTimes = (eventsList) => {
        return eventsList &&
        eventsList
            .filter((holiday) => holiday.rule && holiday.enable)
            .map(
                (holiday) =>
                    holiday.rule && {
                        holiday_name: holiday.holiday_name,
                        start_date: holiday.rule.start_date,
                        holiday_start_date: holiday.start_date,
                        end_date: holiday.rule.end_date,
                        holiday_end_date: holiday.start_date,
                        response_rule: true,
                    }
            )
            .sort(function (a, b) {
                return (
                    new Date(a.holiday_start_date) -
                    new Date(b.holiday_start_date)
                );
            });
    };

    const handleResponse = (response) => {
        const respStatus = response.status;
        if (respStatus === 200) {
            setSuccessAlert(true);
        } else if (respStatus === 400) {
            setSuccessAlert(false);
        }
        setOpenSnackbar(true);
        return response.json();
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
        setErrors(null);
    };

    const updateEventChanged = (newValue, index, field) => {
        let newArr = [...eventsList];
        newArr[index][field] = new Date(newValue);
        setEventsList(newArr);
    };

    const updateEventSettings = (event_settings) => {
        api.patch(`api/v1/holidays-duration/${event_settings.id}/`, {
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                start_date: event_settings.start_date,
                end_date: event_settings.end_date,
            }),
        }).then((response) => {
            handleResponse(response);
        });
    };

    useEffect(() => {
        if (selectedOption === "rules") {
            setSelectedEvents(responseTimesList);
        } else {
            setSelectedEvents(eventsList);
        }
    }, [selectedOption]);

    useEffect(() => {
        api.get("api/v1/holidays-duration/")
            .then((res) => res.json())
            .then((res) => {
                setEventsList(res);
                setSelectedEvents(parseResponseTimes(res));
            });
    }, []);

    return (
        <div>
            <div className={classes.selector}>
                <Grid container alignItems="center">
                    <Grid item xs={6}>
                        <Typography>Holidays events types duration</Typography>
                    </Grid>
                    <Grid item style={{ textAlign: "end" }} xs={6}>
                        <HolidaysOptionSelector
                            selectedOption={selectedOption}
                            setSelectedOption={setSelectedOption}
                        />
                    </Grid>
                </Grid>
            </div>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {columns.map((item) => (
                                <TableCell key={item.id}>
                                    {item.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {selectedEvents.map((row, index) => {
                            return (
                                <TableRow hover tabIndex={-1} key={row.code}>
                                    {columns.map((column) => {
                                        const value = row[column.id];
                                        if (column.id === "holiday_name") {
                                            return (
                                                <TableCell
                                                    key={`${value}_${row.holiday_name}_${row.rule}`}
                                                >
                                                    {value}
                                                </TableCell>
                                            );
                                        } else if (column.id === "save") {
                                            return (
                                                <TableCell
                                                    key={`${value}_${row.holiday_name}_${row.rule}`}
                                                >
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        key={`${value}_${row.holidays}_${row.rule}`}
                                                        onClick={() => {
                                                            updateEventSettings(
                                                                row
                                                            );
                                                        }}
                                                    >
                                                        Save
                                                    </Button>
                                                </TableCell>
                                            );
                                        } else if (column.id === "start_date") {
                                            return (
                                                <TableCell
                                                    key={`${value}_${row.holiday_name}_${row.rule}`}
                                                >
                                                    {
                                                        <MuiPickersUtilsProvider
                                                            utils={DateFnsUtils}
                                                        >
                                                            <KeyboardDateTimePicker
                                                                key={`${value}_${row.holidays}_${row.rule}`}
                                                                value={getUserDateTime(
                                                                    row[
                                                                        column
                                                                            .id
                                                                    ]
                                                                )}
                                                                onChange={(e) =>
                                                                    updateEventChanged(
                                                                        e,
                                                                        index,
                                                                        "start_date"
                                                                    )
                                                                }
                                                                format="MM/dd/yyyy hh:mm a"
                                                                label="Start date"
                                                                inputVariant="outlined"
                                                                disableToolbar
                                                                ampm={false}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    }
                                                </TableCell>
                                            );
                                        } else {
                                            return (
                                                <TableCell
                                                    key={`${value}_${row.holiday_name}_${row.rule}`}
                                                >
                                                    {
                                                        <MuiPickersUtilsProvider
                                                            utils={DateFnsUtils}
                                                            key={`${value}_${row.holidays}_${row.rule}`}
                                                        >
                                                            <KeyboardDateTimePicker
                                                                key={`${value}_${row.holidays}_${row.rule}`}
                                                                value={getUserDateTime(
                                                                    row.end_date
                                                                )}
                                                                onChange={(e) =>
                                                                    updateEventChanged(
                                                                        e,
                                                                        index,
                                                                        "end_date"
                                                                    )
                                                                }
                                                                format="MM/dd/yyyy hh:mm a"
                                                                label="End date"
                                                                inputVariant="outlined"
                                                                disableToolbar
                                                                ampm={false}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    }
                                                </TableCell>
                                            );
                                        }
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            {successAlert && (
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={2500}
                    onClose={handleClose}
                >
                    <Alert
                        onClose={handleClose}
                        severity="success"
                        xs={{ width: "100%" }}
                    >
                        Settings successfully updated!
                    </Alert>
                </Snackbar>
            )}
            {!successAlert && errors && errors.length > 0 && (
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={5000}
                    onClose={handleClose}
                >
                    <Alert
                        onClose={handleClose}
                        severity="error"
                        xs={{ width: "100%" }}
                    >
                        {parseErrors(...errors)}
                    </Alert>
                </Snackbar>
            )}
        </div>
    );
}
